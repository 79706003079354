<template>
    <b-container fluid class="p-2 mb-1 bg-dark border border-primary">
        <b-row class="align-items-center ml-2 mr-2">
            <b-col cols="*">
                <b-button variant="danger" @click="remove" size="lg"><b-icon icon="trash"/></b-button>                
            </b-col>
            <b-col>
                <b-form-input type="text" :disabled="running" v-model="timerData.text" size="lg" @input="textChanged"/>
            </b-col>
            <b-col v-if="!running" cols="*">
                <b-button variant="secondary" :disabled="running" class="ml-1" @click="dec" size="lg"><b-icon icon="arrow-down"/></b-button>                
                <b-button variant="secondary" :disabled="running" class="ml-1" @click="inc" size="lg"><b-icon icon="arrow-up"/></b-button>
            </b-col>                
            <b-col cols="*" style="font-size: 2rem;" :class="'bg-' + timerBgVariant + ' text-light text-center ml-3 pl-3 pr-3 rounded'">
                <span>{{ timerDisplay }}</span>
                <audio ref="alert" style="display: none" id="kcTimerAlert" volume="100" src="../assets/complete.mp3"/>
            </b-col>
            <b-col v-if="!running && !finished" cols="*">
                <b-button variant="primary" @click="run" size="lg"><b-icon icon="play"/></b-button>
            </b-col>
            <b-col v-if="running || finished" cols="*">
                <b-button variant="danger" @click="reset" class="ml-1" size="lg"><b-icon icon="stop"/></b-button>
            </b-col>            
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'kc-timer',
    components: {
    },  
    data() {
        return {    
            running: false,
            finished: false,
            timerBgVariant: 'dark',
            timerInterval: null,
            alertInterval: null
        }
    },
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    computed: {
        timerData: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        },
        timerDisplay() {
            const vm = this;
            var minutes = Math.floor(vm.timerData.timer / 60);
            var seconds = Math.abs(vm.timerData.timer - (minutes * 60));
            return vm.pad(minutes, 2) + ':' + vm.pad(seconds, 2);
        },
    },
    methods: {
        remove() {
            const vm = this;
            vm.reset();
            vm.$emit('remove');
        },
        textChanged() {
            const vm = this;
            vm.$emit('input', vm.value);
        },
        inc() {
            const vm = this;
            vm.timerData.startTime += 60;
            if (vm.timerData.startTime > 21600) vm.timerData.startTime = 21600;
            vm.timerData.timer = vm.timerData.startTime;
            vm.$emit('input', vm.value);
        },
        dec() {
            const vm = this;
            vm.timerData.startTime  -= 60;
            if (vm.timerData.startTime  < 60) vm.timerData.startTime  = 60;
            vm.timerData.timer = vm.timerData.startTime;
            vm.$emit('input', vm.value);
        },
        run() {
            const vm = this;
            var start = vm.$moment();
            vm.running = true;     
            vm.finished = false;
            vm.timerBgVariant = 'success';
            vm.timerData.timer = vm.timerData.startTime;
            vm.timerInterval = setInterval(() => {
                var now = vm.$moment();
                var duration = vm.$moment.duration(now.diff(start));
                vm.timerData.timer = Math.round(vm.timerData.startTime  - duration.asSeconds());
                if (vm.timerData.timer <= 0) {
                    clearInterval(vm.timerInterval);
                    vm.finished = true;
                    vm.running = false;
                    vm.alertSound();
                    var counter = 1;
                    vm.timerBgVariant = 'danger';
                    vm.alertInterval = setInterval(() => {
                        if (vm.timerBgVariant === 'success') {
                            vm.timerBgVariant = 'danger';
                        } else {
                            vm.timerBgVariant = 'success';
                        }
                        counter++;
                        if (counter === 10) {
                            vm.alertSound();
                            counter = 1;
                        }
                    }, 250);                  
                }
            }, 10);
        },
        reset() {
            const vm = this;
            if (vm.timerInterval !== null) {
                clearInterval(vm.timerInterval);
            }
            if (vm.alertInterval !== null) {
                clearInterval(vm.alertInterval);
            }          
            vm.running = false;
            vm.finished = false;
            vm.timerBgVariant = 'dark';
            vm.timerInterval = null;
            vm.alertInterval = null;
            vm.timerData.timer = vm.timerData.startTime;
        },
        pad(num, size) {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        },
        alertSound() {
            const vm = this;
            vm.$refs.alert.play();
        }
    }
}
</script>
<style lang="sass" scoped>
</style>