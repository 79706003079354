<template>
    <div>
        <b-alert v-if="error !== null" show variant="danger">
            {{ error }}
        </b-alert>
        <b-container v-if="data !== null" fluid class="text-light m-4">
            <b-row class="border border-light border-left-0 border-top-0 border-right-0 border-bottom-1">
                <b-col class="p-0">
                    Aktuelles Wetter in {{ data.address }}
                </b-col>
            </b-row>            
            <b-row class="mt-2">
                <b-col cols="*" class="text-center border border-primary border-left-0 border-top-0 border-right-1 border-bottom-0" style="width: 17rem;">
                    <img :src="'/weather-icons/' + data.currentConditions.icon + '.svg'" class="iconFilter" style="width: 10rem; height: 10rem;"/>
                    <div>
                        {{ data.currentConditions.conditions }}
                    </div>
                    <div style="font-size: 2.5rem;">
                        {{ data.currentConditions.temp.toLocaleString() }}° C
                    </div>                    
                </b-col>
                <b-col>
                    <b-container fluid class="mt-3">
                        <b-row>
                            <b-col cols="*" class="text-primary" style="width: 12rem;">Luftfeuchtigkeit:</b-col>
                            <b-col>{{ data.currentConditions.humidity.toLocaleString() }}%</b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="*" class="text-primary" style="width: 12rem;">Sonnenaufgang:</b-col>
                            <b-col>{{ data.currentConditions.sunrise }} Uhr</b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="*" class="text-primary" style="width: 12rem;">Sonnenuntergang:</b-col>
                            <b-col>{{ data.currentConditions.sunset }} Uhr</b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="*" class="text-primary" style="width: 12rem;">Wind:</b-col>
                            <b-col>{{ data.currentConditions.windspeed.toLocaleString() }} km/h</b-col>
                        </b-row>                                                                           
                    </b-container>
                </b-col>
            </b-row>
            <b-tabs pills class="mt-3" style="font-size: 1rem;">
                <b-tab title="Heute">
                    <b-container fluid>
                        <b-row>
                            <b-col cols="*" v-for="s in stunden" v-bind:key="s.datetime">
                                <div class="bg-secondary text-center p-2 mt-2 mb-2 mr-2" style="width: 8rem; height: 10rem; font-size: 1rem;">
                                    <b-icon-clock/> {{ $moment(data.days[0].datetime + ' ' + s.datetime).format('HH:mm') }}
                                    <div class="m-2">
                                        <img :src="'/weather-icons/' + s.icon + '.svg'" class="iconFilter" style="width: 3rem; height: 3rem;"/>
                                    </div>
                                    <div style="font-size: 1.2rem; font-weight: bold;">{{ s.temp.toLocaleString() }}° C</div>                                    
                                    <div style="font-size: 0.8rem;">{{ s.conditions }}</div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-tab>
                <b-tab title="Die nächsten Tage">
                    <b-container fluid>
                        <b-row>
                            <b-col cols="*" v-for="t in tage" v-bind:key="t.datetime">
                                <div class="bg-secondary text-center p-2 mt-2 mb-2 mr-2" style="width: 8rem; height: 10rem; font-size: 1rem;">
                                    <b-icon-calendar/> {{ $moment(t.datetime).format('ddd DD.MM') }}
                                    <div class="mt-2">
                                        <img :src="'/weather-icons/' + t.icon + '.svg'" class="iconFilter" style="width: 3rem; height: 3rem;"/>
                                    </div>
                                    <div style="font-size: 1.2rem; top: px; font-weight: bold;">{{ t.temp.toLocaleString() }}° C</div>                                    
                                    <div style="font-size: 0.8rem;">{{ t.conditions }}</div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-tab>
            </b-tabs>
        </b-container>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        name: 'kc-weather',
        data() {
            return {
                apiUrl: 'https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/Rheindahlen?unitGroup=metric&lang=de&key={apiKey}&contentType=json',
                apiKey: 'ZTHU5KDVW5FEAFZWKML47ZHJ3',
                timer: null,
                data: null,
                prototype: {
                    "latitude" : 38.9697,
                    "longitude" : -77.385,
                    "resolvedAddress" : "Reston, VA, United States",
                    "address" : " Reston,VA",
                    "timezone" : "America/New_York",
                    "tzoffset" : -5,
                    "description":"Cooling down with a chance of rain on Friday.",
                    "icon": null,
                    "days" : [{ //array of days of weather data objects
                        "datetime":"2020-11-12",
                        "datetimeEpoch":1605157200,
                        "temp" : 59.6,
                        "feelslike" : 59.6,
                        "source" : "obs",
                        "hours" : [{  //array of hours of weather data objects
                            "datetime" : "01:00:00"
                        }]
                    }],
                    "alerts" : [{
                            "event" : "Flash Flood Watch",
                            "description" : "..."
                        }
                    ],
                    "currentConditions" : {
                        "datetime" : "2020-11-11T22:48:35",
                        "datetimeEpoch" : 160515291500,
                        "temp" : 67.9
                    }
                },
                error: null
            }
        },
        mounted() {
            const vm = this;
            vm.timer = window.setInterval(() => {
                vm.refresh();
            }, 100000);
            vm.refresh();
        },
        demounted() {
            const vm = this;
            if (vm.timer !== null) vm.timer.stop;
        },
        computed: {
            stunden() {
                const vm = this;
                let tagIndex = 0;
                let stundenIndex = 0;           
                let jetzt = vm.$moment();
                let stunden = [];
                let anzahl = 0;
                while(anzahl < 12) {
                    if (stundenIndex >= vm.data.days[tagIndex].hours.length) {
                        tagIndex++;
                        stundenIndex=0;
                    }                  
                    let tag = vm.data.days[tagIndex];                      
                    let stunde = tag.hours[stundenIndex];
                    if (!tag) {
                        console.log('Tag');
                    }
                    if (!stunde) {
                        console.log('Stunde');
                    }                    
                    let zeit = vm.$moment(tag.datetime + ' ' + stunde.datetime);
                    if (!zeit.isBefore(jetzt)) {
                        stunden.push(stunde);
                        anzahl++;
                    }
                    stundenIndex++;                    
                }
                return stunden;
            },
            tage() {
                const vm = this;
                let tage = [];
                let tagIndex = 1;
                while(tage.length < 12) {
                    tage.push(vm.data.days[tagIndex]);
                    tagIndex++;
                }
                return tage;
            }
        },
        methods: {
            refresh() {
                const vm = this;
                vm.error = null;
                let url = vm.apiUrl;
                url = url.replace('{apiKey}', vm.apiKey);
                axios.get(url)
                    .then((response) => {
                        vm.data = response.data;
                    })
                    .catch((err) => {
                        vm.error = err;
                    });
            }
        }
    }
</script>
<style lang="css" scoped>

    .iconFilter {
        filter: invert(100%) saturate(3500%) brightness(200%);
        opacity: 0.4;
    }

</style>