<template>
    <b-container fluid>
        <b-row class="align-items-center">
            <b-col>
                <div class="text-right" style="font-size: 2rem; line-height: 2rem;">{{ datum }}</div>
            </b-col>
            <b-col cols="*">
                <div style="font-size: 5rem; line-height: 5rem; font-weight: bold;">{{ uhrzeit_std }}<span :class="trennerFarbe">:</span>{{ uhrzeit_min }}</div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'kc-clock',
    data() {
        return {
            zeit: this.$moment(),
            timer: null,
            trennerFarbe: 'text-primary'          
        }
    },
    computed: {
        uhrzeit_std() {
            const vm = this;
            return vm.zeit.format('HH');
        },                
        uhrzeit_min() {
            const vm = this;
            return vm.zeit.format('mm');
        },                
        datum() {
            const vm = this;
            return vm.zeit.format('dddd DD. MMMM YYYY');
        }
    },
    mounted() {
        const vm = this;
        vm.timer = window.setInterval(() => {
            vm.zeit = vm.$moment();
            if (vm.trennerFarbe == 'uhrzeitTrennerSichtbar')
                vm.trennerFarbe = 'uhrzeitTrennerUnsichtbar';
            else
                vm.trennerFarbe = 'uhrzeitTrennerSichtbar';
        }, 1000);
    },
    demounted() {
        const vm = this;
        if (vm.timer !== null) vm.timer.stop();
    }
}
</script>
<style lang="scss" scoped>

    @import '../styles.scss';

    .uhrzeitTrennerSichtbar
    {
        color: $body-color;
    }

    .uhrzeitTrennerUnsichtbar
    {
        color: $body-bg;
    }

</style>
