<template>
  <div class="h-100">
    <b-sidebar
      id="sidebar-appmenu"
      no-header
      backdrop
      shadow
      width="450px">
      <template slot="title">
        <span class="font-weight-normal">kitchen</span>Companion
      </template>
      <b-container fluid class="p-3">
        <h1>Digitaler Haushaltsassistent</h1>
        <div class="small text-center">
          <strong>Version:</strong> {{ version }}
        </div>
        <div class="small text-center">
          <div><strong>Cloud-Anbieter:</strong> Google</div>
          <b-button v-if="!isSignedIn" @click="login">Login</b-button>
          <b-button v-if="isSignedIn" @click="logout">Logout</b-button>
        </div>
        <div class="text-center small mt-3 p-3 border border-primary border-top-1 border-left-0 border-right-0 border-bottom-0" style="font-size: 1rem;">
          <strong>Impressum</strong><br/>
          NDG IT-Systeme GmbH<br/>
          Dortansstraße 22a<br/>
          41179 Mönchengladbach<br/><br/>
          <strong>Support</strong><br/>
          <a href="mailto:support@ndg.de">support@ndg.de</a>
        </div>
      </b-container>
    </b-sidebar>   
    <b-container fluid class="p-3 border border-light border-left-0 border-top-0 border-right-0 border-bottom-1" size="height: 20rem;">
      <b-row class="align-items-center">
        <b-col cols="*">
          <div role="button" style="font-size: 2rem;" v-b-toggle.sidebar-appmenu><b-icon icon="list"></b-icon><span class="ml-2">Digitaler Haushaltassistent</span></div>                 
        </b-col>
        <b-col>
          <kc-clock class="mt-2"/>  
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="position-fixed p-0 m-0 h-100 ">
      <b-container fluid class="content h-100 p-0">
        <b-row class="h-100 w-100 p-0 m-0">
          <b-col cols="*" class="bg-dark h-100 pr-0 pl-0" style="width: 31rem;">
            <b-container fluid class="p-2">
              <span><b-icon icon="people"/> Familien-Kalender</span>
            </b-container>
            <b-container fluid class="p-2">
              <kc-calendar v-if="isSignedIn"/>
            </b-container>            
          </b-col>
          <b-col cols="*" class="bg-dark ml-1 pl-0 pr-0" style="width: 31rem;">
            <b-container fluid class="p-2">
              <span><b-icon icon="people"/> Müllentsorgung</span>
            </b-container>
            <b-container fluid class="p-2">
              <kc-disposal v-if="isSignedIn"/>
            </b-container>
          </b-col>           
          <b-col class="bg-dark ml-1 pl-0 pr-0">
            <b-container fluid class="h-100 p-0 m-0">
              <b-row class="h-100">
                <b-col>
                  <b-tabs pills nav-class="border border-light border-left-0 border-top-0 border-right-0 border-bottom-0" active-nav-item-class="bg-dark text-body">
                    <b-tab>
                        <template slot="title">
                          <b-icon icon="cloud"/> Wetter
                        </template>                      
                        <kc-weather/>
                      </b-tab>                      
                    <b-tab>                      
                      <template slot="title">
                        <b-icon icon="clock"/> Timer
                      </template>
                      <div class="mt-3 p-2">
                        <kc-timers/>
                      </div>
                    </b-tab>
                    <b-tab>
                     <template slot="title">
                        <b-icon-card-list/> Essensplan
                      </template>                      
                      <kc-menuplan/>
                    </b-tab>                       
                    <b-tab>
                     <template slot="title">
                        <b-icon icon="broadcast-pin" v-if="!radioPlaying"/><b-icon v-if="radioPlaying" icon="broadcast-pin" animation="fade"/> Radio
                      </template>                      
                      <kc-radio v-model="radioPlaying"/>                      
                    </b-tab>             
                    <b-tab>
                     <template slot="title">
                        <b-icon icon="film"/> Medien
                      </template>                      
                      <kc-media/>  
                    </b-tab>                                        
                  </b-tabs>                  
                </b-col>
              </b-row>              
            </b-container>                        
          </b-col>                              
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>
<script>
import kcCalendar from './components/calendar.vue';
import kcWeather from './components/weather.vue';
import kcMenuplan from './components/menuplan.vue';
import kcRadio from './components/radio.vue';
import kcMedia from './components/media.vue';
import kcClock from './components/clock.vue';
import kcTimers from './components/timers.vue';
import kcDisposal from './components/disposal.vue';
export default {
  name: 'App',
  components: {
    kcCalendar,
    kcWeather,
    kcMenuplan,
    kcRadio,
    kcMedia,
    kcClock,
    kcTimers,
    kcDisposal
  },
  data() {
    return {      
      version: '2.0.3',
      uhrzeit: null,
      isSignedIn: false,
      radioPlaying: false
    }
  },
  created() {
    const vm = this;    
    vm.startClock();
    vm.$gapi.listenUserSignIn((isSignedIn) => {
      vm.isSignedIn = isSignedIn
    });
  },
  computed: {
    userName() {
      var userData = this.$gapi.getUserData();
      if (userData !== undefined && userData !== null) {
        return userData.firstName + ' ' + userData.lastName;
      }
      return null;
    },
  },  
  methods: {
    startClock() {
      const vm = this;
      vm.uhrzeit = vm.$moment().format('HH:mm');
      vm.user = null;
      window.setInterval(function() {
        vm.uhrzeit = vm.$moment().format('HH:mm');
      }, 1000)    
    },
    login() {
      const vm = this;
      vm.$gapi.login();
    },
    logout() {
      const vm = this;
      vm.$gapi.logout();
    },
    exit() {
      window.close();
    }
  }
}
</script>
<style lang="scss">
  @import './styles.scss';
  html {
    position: relative;
    min-height: 100%;
  }  
  body {
    margin-bottom: 0rem;    
  }
.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 4rem;
}
.content {
  display: flex;
  width: 100%;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

</style>
