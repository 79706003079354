<template>
    <div class="h-100">
        <b-container class="pl-0 pr-0" fluid>
            <b-alert v-if="error !== null" :show="error !== null" variant="danger" class="small">            
                <strong>Google-Kalender Fehler</strong><br/>
                {{ error.code }} - {{ error.status }}<br/>            
                {{ error.message }}<br/>            
            </b-alert>
            <div v-if="(loadingEvents) && firstLoad" class="text-center">
                <b-spinner/>
            </div>
            <kc-calendar-select v-show="calendarId === null && !loadingEvents" v-model="calendarId"/>
            <b-tabs pills v-if="calendarId !== null && error === null && !firstLoad" class="mt-3">
                <b-tab>
                    <template slot="title">                        
                        Aktuell
                        <b-badge pill v-if="currentEvents.length > 0" variant="secondary" class="m-0">{{ currentEvents.length }}</b-badge>
                    </template>
                    <h2 class="border border-light border-left-0 border-top-0 border-right-0 border-bottom-1">Heute</h2>
                    <div v-if="todayEvents.length === 0" class="text-center mt-3 small">
                        <b-icon icon="info-circle"/><br/>
                        <span>Keine Termine heute!</span>
                    </div>
                    <div v-for="event in todayEvents" v-bind:key="event.id" class="text-light rounded rounded-2 p-2 mt-2">
                        <div v-if="event.start.dateTime !== undefined" class="small"><b-icon-clock/> {{ $moment(event.start.dateTime).format('HH:mm') }}</div>
                        <div class="font-weight-bold">{{ event.summary }}</div>
                    </div>
                    <h2 class="border border-light border-left-0 border-top-0 border-right-0 border-bottom-1">Morgen</h2>
                    <div v-if="todayEvents.length === 0" class="text-center mt-3 small">
                        <b-icon icon="info-circle"/><br/>
                        <span>Keine Termine morgen!</span>
                    </div>
                    <div v-for="event in tomorrowEvents" v-bind:key="event.id" class="text-light rounded rounded-2 p-2 mt-2">
                        <div v-if="event.start.dateTime !== undefined" class="small"><b-icon-clock/> {{ $moment(event.start.dateTime).format('HH:mm') }}</div>
                        <div class="font-weight-bold">{{ event.summary }}</div>
                    </div>                    
                </b-tab>
                <b-tab title="Woche" class="pt-2">
                    <b-row v-for="day in weekDays" v-bind:key="day.date" :class="'align-items-center ' + dayBg(day) + ' border border-light border-top-0 border-left-0 border-right-0 border-bottom-1 mt-0 ml-2 mr-2'">
                        <b-col cols="*" class="pt-4 pb-4 mr-2">
                            {{ $moment(day.date).format('ddd') }}
                        </b-col>
                        <b-col>
                            <b-row v-for="event in day.events" v-bind:key="event.id" class="align-items-center small text-light rounded rounded-2 p-1">
                                <b-col v-if="event.start.dateTime !== undefined" cols="*" class="small pl-3">{{ $moment(event.start.dateTime).format('HH:mm') }} Uhr</b-col>
                                <b-col>{{ event.summary }}</b-col>
                            </b-row>                        
                        </b-col>                                        
                    </b-row>
                </b-tab>
                <b-tab title="Monat" class="pt-2">
                    <b-container fluid>
                        <b-row class="align-items-center mb-2">
                            <b-col cols="*">
                                <b-button><b-icon icon="arrow-left" @click="prevMonth"/></b-button>
                            </b-col>
                            <b-col class="text-center text-light">
                                <span class="font-weight-bold" style="font-size: 1.5rem;">{{ monthDate.format('MMMM YYYY') }}</span>
                            </b-col>
                            <b-col cols="*">
                                <b-button><b-icon icon="arrow-right" @click="nextMonth"/></b-button>
                            </b-col>                        
                        </b-row>
                        <b-row class="text-center text-primary small border border-dark border-bottom-1 border-top-1 border-left-1 border-right-1">
                            <b-col class="pt-4 pb-4">Mo</b-col>
                            <b-col class="pt-4 pb-4">Di</b-col>
                            <b-col class="pt-4 pb-4">Mi</b-col>
                            <b-col class="pt-4 pb-4">Do</b-col>
                            <b-col class="pt-4 pb-4">Fr</b-col>
                            <b-col class="pt-4 pb-4 bg-secondary">Sa</b-col>
                            <b-col class="pt-4 pb-4 bg-secondary">So</b-col>
                        </b-row>
                        <b-row v-for="week in monthWeeks" v-bind:key="week.date" class="text-center border border-dark border-bottom-1 border-top-0 border-left-1 border-right-1">
                            <b-col v-for="day in week.days" v-bind:key="day.date" :class="'pt-4 pb-4 ' + dayBg(day)">
                                <div v-if="day.today" class="bg-danger text-white p-1">{{ day.day }}</div>
                                <div v-if="!day.today" :class="dayBg(day) + ' p-1'">{{ day.day }}</div>
                                <div v-if="day.events.length > 0" class="bg-primary text-white p-1 rounded mt-1" style="font-size: 0.5rem;" role="button" @click="showEventsDialog(day)">
                                    {{ day.events.length }}
                                </div>                         
                            </b-col>
                        </b-row>             
                    </b-container>
                </b-tab>            
            </b-tabs>
        </b-container>
        <b-modal v-if="eventDialog.show === true" v-model="eventDialog.show" :title="'Termine am ' + $moment(eventDialog.day.dateTime).format('dddd, DD. MMMM YYYY')"  size="xl" hideFooter header-bg-variant="secondary" body-bg-variant="dark" header-close-variant="light">
            <div v-for="event in eventDialog.day.events" v-bind:key="event.id" class="text-light rounded rounded-2 p-2 mt-2">
                <div v-if="event.start.dateTime !== undefined" class="small"><b-icon-clock/> {{ $moment(event.start.dateTime).format('HH:mm') }}</div>
                <div class="font-weight-bold">{{ event.summary }}</div>
            </div>            
        </b-modal>
    </div>
</template>
<script>
import kcCalendarSelect from './calendar-select.vue';
export default {
    name: 'kc-calendar',
    components: {
        kcCalendarSelect
    },
    data() {
        return {    
            error: null,
            availableCalendars: [],
            calendarId: null,
            firstLoad: true,
            loadingEvents: false,
            lastRefresh: null,
            events: [],
            monthDate: null,
            monthWeeks: null,
            eventDialog: {
                show: false,
                date: null
            }
        }
    },
    computed: {      
        currentEvents() {
            const vm = this;
            return vm.todayEvents.concat(vm.tomorrowEvents);
        },
        todayEvents() {
            const vm = this;
            var events = vm.getEventsOfDate(vm.$moment());
            return events;
        },        
        tomorrowEvents() {
            const vm = this;
            var events = vm.getEventsOfDate(vm.$moment().add(1, 'days'));
            return events;
        },
        weekDays() {
            const vm = this;            
            var days = [];
            var date = vm.$moment();
            for(var i = 1; i < 8; i++) {
                var day = {
                    date: date.format('YYYY-MM-DD'),
                    events: vm.getEventsOfDate(date)
                };
                days.push(day);
                date = date.add(1, 'days');
            }
            return days;
        }
    },
    created() {
        const vm = this;
        if (!vm.$gapi.isSignedIn()) {
        vm.$gapi.login().then(() => {
            vm.init();
        });            
        } else {
            vm.init();
        }
    },    
    watch: {
        calendarId() {
            const vm = this;
            localStorage["kc.calendarId"] = vm.calendarId;
        }
    },
    methods: {         
        init() {
            const vm = this;
            vm.monthDate = vm.$moment(vm.$moment().format('YYYY-MM-') + '01');
            if (localStorage["kc.calendarId"] !== undefined && localStorage["kc.calendarId"] !== null) {
                vm.calendarId = localStorage["kc.calendarId"];
                vm.loadEvents();
                vm.startRefresh();
            }
        },
        startRefresh() {
            const vm = this;
            if (vm.refreshInterval !== null) {
                vm.stopRefresh();
            }
            vm.refreshInterval = setInterval(function() {
                if (!vm.loadingEvents) {
                    vm.loadEvents();
                    vm.lastRefresh = vm.$moment();
                }
            }, 10000);
        },
        stopRefresh() {
            const vm = this;
            if (vm.refreshInterval) {
                clearInterval(vm.refreshInterval);
            }
        },
        loadEvents() {
            const vm = this;
            vm.error = null;        
            vm.loadingEvents = true;
            var startDate = vm.$moment().toDate().toISOString();
            var endDate = vm.$moment().add(600, 'days').toDate().toISOString();
            vm.$gapi.getGapiClient().then((gapi) => {
                gapi.client.calendar.events.list({
                    calendarId: vm.calendarId,
                    timeMin: startDate,
                    timeMax: endDate,
                    showDeleted: false,
                    singleEvents: true,
                    maxResults: 2500,
                    orderBy: 'startTime'                
                })
                .then(response => {
                    vm.loadingEvents = false;
                    vm.firstLoad = false;
                    vm.events = response.result.items;
                    vm.updateMonthWeeks();
                })
                .catch((response) => {
                    vm.loadingEvents = false;
                    vm.firstLoad = false;
                    vm.error = response.result.error;
                });                
            });
        },
        getEventsOfDate(date) {
            const vm = this;
            return vm.events.filter(x => (x.start.date !== undefined && x.start.date === date.format('YYYY-MM-DD'))
                                            || (x.start.dateTime !== undefined && x.start.dateTime.startsWith(date.format('YYYY-MM-DD'))));     
        },
        dayBg(day) {
            const vm = this;
            var weekday = vm.$moment(day.date).format('dd');
            if (weekday === "Sa" || weekday === "So") {
                return "bg-secondary";
            }
            return "";
        },
        updateMonthWeeks() {
            var vm = this;
            vm.monthWeeks = [];
            var startDate = vm.$moment(vm.monthDate.format('YYYY-MM-DD'));
            if (startDate.format('dd') === "Di") startDate = startDate.add(-1, 'days');
            if (startDate.format('dd') === "Mi") startDate = startDate.add(-2, 'days');
            if (startDate.format('dd') === "Do") startDate = startDate.add(-3, 'days');
            if (startDate.format('dd') === "Fr") startDate = startDate.add(-4, 'days');
            if (startDate.format('dd') === "Sa") startDate = startDate.add(-5, 'days');
            if (startDate.format('dd') === "So") startDate = startDate.add(-6, 'days');
            var date = startDate;
            var week = {
                days: []
            };
            var exitWhile = false;
            while(!exitWhile)
            {
                var day = {
                    date: date.format('YYYY-MM-DD'),
                    day: date.format('D'),
                    today: (date.format('YYYY-MM-DD') === vm.$moment().format('YYYY-MM-DD')),
                    otherMonth: (date.format('MMM') !== vm.monthDate.format('MM')),
                    events: vm.getEventsOfDate(date)
                }
                week.days.push(day);
                if (date.format('dd') === "So") {
                    vm.monthWeeks.push(week);
                    week = {
                        days: []
                    };
                    date = date.add(1, 'days');                    
                    if (date.format('MM') !== vm.monthDate.format('MM')) exitWhile = true;
                } else {
                    date = date.add(1, 'days');
                }
            }
        },        
        prevMonth() {
            const vm = this;
            vm.monthDate = vm.monthDate.add(-1, 'month');
            vm.updateMonthWeeks();
        },
        nextMonth() {
            const vm = this;
            vm.monthDate = vm.monthDate.add(1, 'month');
            vm.updateMonthWeeks();
        },        
        showEventsDialog(day) {
            const vm = this;
            vm.eventDialog.show = true;
            vm.eventDialog.day = day;
        }
    }
}
</script>
<style lang="sass" scoped>
</style>