<template>
    <div class="mt-1 ml-3 mr-3 mb-1" style="font-size: 1rem;">
        <b-container fluid class="mb-2">
            <b-row>
                <b-col cols="pl-2">
                    <b-button @click="poolOeffnen"><b-icon-pen/> Gerichte-Pool</b-button>
                    <b-dropdown class="ml-2">
                        <template v-slot:button-content>
                            <b-icon-stack/> Automatischer Plan
                        </template>
                        <b-dropdown-item>Alle Tage / vorhandene überschreiben</b-dropdown-item>
                        <b-dropdown-item>Nur noch nicht verplante Tage</b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid style="height: 44rem; overflow: auto;">
            <b-row v-for="t in tage" v-bind:key="t.Id" :class="(t.istWochenende ? 'bg-secondary' : 'bg-dark') + ' align-items-center border border-light p-2 mb-1'" style="height: 2.8rem;">
                <b-col cols="*" class="pl-2" style="width: 10rem;">
                    <b-icon-calendar/> {{ $moment(t.Datum).format('ddd DD. MMM') }}
                </b-col>
                <b-col>
                    <span v-if="t.Gericht !== null"></span>
                    <span v-else class="text-primary">(noch kein Gericht geplant)</span>
                </b-col>
                <b-col cols="*" class="pr-2">
                    <b-dropdown size="sm">
                        <template v-slot:button-content>
                            <b-icon-pen/>
                        </template>
                        <b-dropdown-item>Auswählen aus Gerichte-Pool...</b-dropdown-item>
                        <b-dropdown-item>Automatische Auswahl</b-dropdown-item>                        
                    </b-dropdown>
                </b-col>
            </b-row>
        </b-container>
        <b-modal v-if="pool.show === true" v-model="pool.show"
            title="Gerichte-Pool"
            size="xl"
            body-bg-variant="white"
            header-bg-variant="dark"
            header-close-variant="light" 
            footer-bg-variant="dark"
            okOnly
            okTitle="Schließen"
            scrollable>
            <b-container fluid class="text-dark">
                <b-row>
                    <b-col class="pl-0">
                        <div class="pl-0">
                            <b-button @click="neuesGericht" variant="success"><b-icon-plus-circle/> Neues Gericht anlegen</b-button>
                        </div>
                        <div v-if="pool.gerichte.length == 0" class="text-primary small border border-light p-2 mt-2">
                            <b-icon-info-circle/> Noch keine Gerichte im Pool vorhanden.
                        </div> 
                    </b-col>
                </b-row>               
                <b-row v-for="g in pool.gerichte" v-bind:key="g.Id" class="border border-light border-left-0 border-top-0 border-right-0 border-bottom-1">
                    <b-col cols="*">
                        <b-button size="sm p-1" @click="gerichtBearbeiten(g)"><b-icon-pen/></b-button>
                        <b-button size="sm p-1 ml-2" variant="danger" @click="gerichtLoeschen(g)"><b-icon-trash/></b-button>
                    </b-col>
                    <b-col>
                        {{ g.Bezeichnung }} 
                    </b-col>
                    <b-col cols="*">
                        <b-icon-star-fill role="button" @click="sterneSetzen(g, 1)"/>
                        <b-icon-star-fill role="button" v-if="g.Sterne >= 2" class="ml-2" @click="sterneSetzen(g, 2)"/>
                        <b-icon-star role="button" v-else class="ml-2" @click="sterneSetzen(g, 2)"/>
                        <b-icon-star-fill role="button" v-if="g.Sterne == 3" class="ml-2" @click="sterneSetzen(g, 3)"/>
                        <b-icon-star role="button" v-else class="ml-2" @click="sterneSetzen(g, 3)"/>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <b-modal v-if="pool.edit.show === true && pool.edit.model !== null" v-model="pool.edit.show"
            title="Gericht"
            header-text-variant="light"
            header-bg-variant="dark"
            footer-bg-variant="dark"
            cancelTitle="Abbruch"
            okTitle="Übernehmen"
            cancelVariant="danger"
            okVariant="success"
            :okDisabled="pool.edit.model.Bezeichnung == ''"
            @ok="gerichtSpeichern">
            <b-input v-model="pool.edit.model.Bezeichnung"></b-input>
            <div class="text-dark small">
                <b-form-checkbox v-model="pool.edit.model.Auswaerts" size="lg" switch :disabled="pool.edit.model.LieferdienstAbholen === true">Auswärts essen</b-form-checkbox>
                <b-form-checkbox v-model="pool.edit.model.LieferdienstAbholen" size="lg" switch :disabled="pool.edit.model.Auswaerts === true">Lieferdienst / abholen</b-form-checkbox>
            </div>
        </b-modal>
    </div>
</template>
<script>
    export default {
        name: 'kc-menuplan',
        data() {
            return {
                tage: [],
                pool: {
                    show: false,
                    gerichte: [
                    ],
                    edit: {
                        show: false,
                        model: null,
                        isNew: false
                    }
                }         
            }
        },
        mounted() {
            const vm = this;
            vm.init();
        },
        methods: {
            init() {
                const vm = this;
                vm.loadPool();
                let heute = vm.$moment();
                while(vm.tage.length < 15) {
                    vm.tage.push({
                        Id: heute.format('YYYY-MM-DD'),
                        Datum: heute.toDate(),
                        istWochenende: (heute.format('dd') == 'Sa' || heute.format('dd') == 'So'),
                        Gericht: null
                    });
                    heute.add(1, 'day');
                }
            },
            loadPool() {
                const vm = this;
                let jsonPool = localStorage.getItem('kc_menu_plan_pool');
                if (jsonPool !== null) {
                    vm.pool.gerichte = JSON.parse(jsonPool);
                }
            },
            poolOeffnen() {
                const vm = this;
                vm.pool.show = true;
            },
            sterneSetzen(gericht, anzahl) {
                const vm = this;
                gericht.Sterne = anzahl;
                vm.savePool();
            },
            gerichtLoeschen(gericht) {
                const vm = this;
                let index = vm.pool.gerichte.indexOf(gericht);
                if (index >= 0) {
                    vm.pool.gerichte.splice(index, 1);
                    vm.savePool();                    
                }                
            },
            neuesGericht() {
                const vm = this;
                vm.pool.edit.model = {
                    Id: vm.generateUUID(),
                    Bezeichnung: '',
                    Auswaerts: false,
                    LieferdienstAbholen: false,
                    Sterne: 2
                };
                vm.pool.edit.isNew = true;
                vm.pool.edit.show = true;                
            },
            gerichtSpeichern() {
                const vm = this;
                if (vm.pool.edit.isNew === true) {
                    vm.pool.gerichte.push(vm.pool.edit.model);
                } else {
                    let gericht = vm.pool.gerichte.find(x => x.Id == vm.pool.edit.model.Id);
                    if (gericht) {
                        for(let prop in vm.pool.edit.model) {
                            gericht[prop] = vm.pool.edit.model[prop];
                        }
                    }
                }
                vm.savePool();
            },
            gerichtBearbeiten(gericht) {
                const vm = this;
                let json = JSON.stringify(gericht);
                vm.pool.edit.model = JSON.parse(json);
                vm.pool.edit.isNew = false;
                vm.pool.edit.show = true;
            },
            savePool() {
                const vm = this;
                let json = JSON.stringify(vm.pool.gerichte);
                localStorage.setItem('kc_menu_plan_pool', json);                
            },
            generateUUID() { // Public Domain/MIT
                var d = new Date().getTime();//Timestamp
                var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    var r = Math.random() * 16;//random number between 0 and 16
                    if(d > 0){//Use timestamp until depleted
                        r = (d + r)%16 | 0;
                        d = Math.floor(d/16);
                    } else {//Use microseconds since page-load if supported
                        r = (d2 + r)%16 | 0;
                        d2 = Math.floor(d2/16);
                    }
                    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
            }            
        }
    }
</script>
<style scoped>

</style>