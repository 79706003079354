<template>
    <div>
        <b-alert v-if="error !== null" dismissible variant="danger" show class="mt-3 small ml-3 mr-3">
            <span><b-icon icon="exclamation-triangle"/> {{ error }}</span>
        </b-alert>
        <b-container fluid>
            <b-row class="align-items-center mt-2 ml-0 mr-0 bg-light border border-dark rounded p-3">
                <b-col cols="*">
                    <b-button variant="primary" size="lg" :disabled="selectedStation === null || playing === true" @click="play"><b-icon icon="play"/></b-button>
                </b-col>
                <b-col cols="*">
                    <b-button variant="dark" size="lg" class="ml-2" :disabled="selectedStation === null || playing === false" @click="stop"><b-icon icon="stop"/></b-button>
                </b-col>
                <b-col cols="*" v-show="selectedStation !== null && playing === true">
                    <b-icon icon="music-note-beamed" animation="fade" font-scale="2" class="ml-2"/>
                    <audio ref="audio" style="display: none" id="kcRadio" :src="currentStreamUrl"/>
                </b-col>                                                      
                <b-col class="bg-white border border-dark rounded text-dark pl-2 pt-1 pr-2 pb-1 ml-2 mr-2">
                    <b-row class="align-items-center">
                        <b-col class="text-left">
                            <span v-if="selectedStation !== null" class="font-weight-bold"><b-icon icon="broadcast-pin"/> {{ selectedStation.display_name }}</span>
                            <span v-if="selectedStation === null">(kein Sender ausgewählt)</span>
                        </b-col>
                        <b-col cols="*" class="text-primary text-right mr-3 small">
                            <span v-if="!muted"><b-icon icon="volume-up"/> {{ volume }}%</span>
                            <span v-if="muted"><b-icon icon="volume-mute"/> stumm</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="*">
                    <b-button v-if="muted === false" variant="primary" @click="toggleMute" class="mr-2"><b-icon icon="volume-mute-fill" font-scale="2"/></b-button>
                    <b-button v-if="muted === true" variant="danger" @click="toggleMute" class="mr-2"><b-icon icon="volume-mute-fill" animation="fade" font-scale="2"/></b-button>                    
                </b-col>             
                <b-col cols="*">                
                    <b-button variant="primary" @click="changeVolume(-10)" class="mr-2"><b-icon icon="volume-down-fill" font-scale="2"/></b-button>
                </b-col>                
                <b-col cols="*">                    
                    <b-button  variant="primary" @click="changeVolume(10)"><b-icon icon="volume-up-fill" font-scale="2"/></b-button>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid>
            <b-row class="bg-light border border-dark rounded mt-2 ml-0 mr-0 pt-0 pl-2 pr-2 pb-3">
                <b-col>
                    <h2><b-icon icon="person"/> Meine Favoriten-Sender</h2>
                    <b-row class="ml-0 mr-0">
                        <b-col v-if="myStations.length === 0">
                            <b-alert variant="info" show class="small">
                                <span><b-icon icon="info"/> Sie haben keine Sender in Ihren Favoriten abgelegt.</span>
                            </b-alert>
                        </b-col>
                        <b-col v-for="station in myStations" v-bind:key="station.stream_url" class="bg-white p-2 rounded text-dark border border-dark small mr-1 mb-1">
                            <span class="mr-2"><b-icon icon="broadcast-pin"/> {{ station.display_name }}</span>
                            <b-button variant="primary" @click="select(station)"><b-icon icon="play"/></b-button>
                            <b-button variant="danger" @click="removeFromMyStations(station)" class="ml-1"><b-icon icon="x"/></b-button>
                        </b-col>
                    </b-row>                    
                </b-col>
            </b-row>
            <b-row class="bg-light border border-dark rounded mt-2 ml-0 mr-0 pt-0 pl-2 pr-2 pb-3">
                <b-col>
                    <h2><b-icon icon="search"/> Suche</h2>
                    <kc-keyboard v-model="searchQuery" :autoAccept="false" @onAccepted="search" class="mb-2"/>
                    <b-alert v-if="searchResult !== null && searchResult.length === 0" variant="warning" show class="small">
                        <span><b-icon icon="exclamation-triangle"/> Die Suche lieferte keine Ergebnisse.</span>
                    </b-alert> 
                    <b-row class="ml-0 mr-0">
                        <b-col cols="*" v-for="station in searchResult" v-bind:key="station.stream_url" class="bg-white p-2 border border-dark text-dark rounded text-light small mr-1 mb-1">
                            <span><b-icon icon="broadcast-pin"/> {{ station.display_name }}</span>
                            <b-button variant="primary" @click="select(station)" class="ml-2"><b-icon icon="play"/></b-button>
                            <b-button variant="success" @click="addToMyStations(station)" class="ml-1"><b-icon icon="person-plus"/></b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>            
        </b-container>
    </div>
</template>
<script>
const STORAGE_VAR_RADIOSTATIONS = "kc.radiostations";
const STORAGE_VAR_LASTSTATION= "kc.radiolaststation";
const STORAGE_VAR_RADIOVOLUME = "kc.radiovolume";
import Lautfm from 'lautfm';
import kcKeyboard from './keyboard.vue';
export default {
    name: 'kc-radio',
    components: {
        kcKeyboard
    },
    data() {
        return {            
            laut: null,
            error: null,
            myStations: [],
            searchQuery: null,
            searchResult: null,
            selectedStation: null,
            volume: 80,
            muted: false,
            presetStations: [
                { 
                    display_name: '90.1 MG',
                    name: '90.1mg',
                    stream_url: 'https://radiomg-ais-edge-4004-dus-dtag-cdn.cast.addradio.de/radiomg/live/mp3/high?ar-distributor=ffa0&_art=dj0yJmlwPTg0LjE0MC4zNC4xNDUmaWQ9aWNzY3hsLWptbWVhc2ltYiZ0PTE2MTMzNzk5OTImcz03ODY2ZjI5YyMyYWIyNTdhZmI2YzVlYjg3ODRlOTliODE1MzZmN2RiMg'
                },                 
                { 
                    display_name: 'WDR 2',
                    name: 'wdr2',
                    stream_url: 'http://wdr-wdr2-rheinruhr.icecast.wdr.de/wdr/wdr2/rheinruhr/mp3/128/stream.mp3'
                },                                                
                { 
                    display_name: 'Welle NR',
                    name: 'welle_nr',
                    stream_url: 'http://mp3.wellenr.c.nmdn.net/wellenr/livestream.mp3'
                }
            ]
        }
    },
    props: {
        value: null
    },
    created() {
        const vm = this;
        vm.playing = false;
        vm.laut = new Lautfm();
        var savedStations = localStorage[STORAGE_VAR_RADIOSTATIONS];
        if (savedStations !== undefined) {
            vm.myStations = JSON.parse(savedStations);
        }
        var lastStation = localStorage[STORAGE_VAR_LASTSTATION];
        if (lastStation !== undefined) {
            vm.selectedStation = JSON.parse(lastStation);
        }
        var initVolume = localStorage[STORAGE_VAR_RADIOVOLUME];
        if (initVolume !== undefined) {
            vm.volume = JSON.parse(initVolume);
        }
        vm.addPresetStations();
    },
    computed: {
        playing: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        },
        currentStreamUrl() {
            let vm = this;
            if (vm.selectedStation !== null && vm.selectedStation !== undefined) {
                return vm.selectedStation.stream_url
            }
            return '';
        },
        outputVolume() {
            const vm = this;
            if (vm.muted === true) {
                return 0;
            }
            return (vm.volume / 100);
        }
    },
    watch: {
        volume() {
            const vm = this;
            localStorage[STORAGE_VAR_RADIOVOLUME] = JSON.stringify(vm.volume);
        },
        outputVolume() {
            const vm = this;
            vm.setVolume();
        }
    },
    methods: {
        search() {
            const vm = this;
            vm.searchResult = null;
            if (vm.searchQuery === null || vm.searchQuery === '') {
                return;
            }
            vm.laut.searchStations({ query: vm.searchQuery, limit: 25})
            .then(data => {
                vm.searchResult = [];
                data.results.forEach(result => {
                    result.items.forEach(item => {
                        vm.searchResult.push(item.station);
                    });
                });
            })
            .catch(err => {
                vm.error = err;
            });
        },
        select(station) {
            const vm = this;
            vm.selectedStation = station;
            vm.searchQuery = null;
            vm.searchResult = null;
            vm.play();
        },
        addToMyStations(station) {
            const vm = this;
            vm.myStations.push(station);
            localStorage[STORAGE_VAR_RADIOSTATIONS] = JSON.stringify(vm.myStations);
        },
        removeFromMyStations(station) {
            const vm = this;
            var index = vm.myStations.indexOf(station);
            if (index > -1) {
                vm.myStations.splice(index, 1);
                localStorage[STORAGE_VAR_RADIOSTATIONS] = JSON.stringify(vm.myStations);
            }
        },
        play() {
            const vm = this;
            if (vm.selectedStation === null) return;
            localStorage[STORAGE_VAR_LASTSTATION] = JSON.stringify(vm.selectedStation);
            vm.setVolume();   
            vm.$refs.audio.src = vm.selectedStation.stream_url;
            vm.$refs.audio.play();         
            vm.playing = true;            
        },
        stop() {
            const vm = this;
            vm.$refs.audio.src = "";
            vm.playing = false;
        },
        toggleMute() {
            const vm = this;
            vm.muted = !vm.muted;
        },
        setVolume() {
            const vm = this;
            if (vm.$refs.audio !== undefined) {
                vm.$refs.audio.volume = vm.outputVolume;
            }            
        },
        changeVolume(changeValue) {
            const vm = this;
            var newVolume = vm.volume;
            newVolume = newVolume + changeValue;
            if (newVolume < 0 ) newVolume = 0;
            if (newVolume > 100) newVolume = 100;
            vm.volume = newVolume;
        },
        addPresetStations() {
            const vm = this;
            vm.presetStations.forEach(s => {
                var existing = vm.myStations.find(x => x.stream_url === s.stream_url);
                if (existing === undefined) {
                    vm.myStations.push(s);
                }
            });
        }
    }
}
</script>